import * as React from 'react';
import { useEffect } from 'react';
import { Card, CardContent, Typography, CircularProgress } from '@mui/material';
import { styled } from "@mui/material/styles"

import { Sound } from '../../../../types/Sound';
import axios from 'axios';
import { getCsrfToken } from '../../../../utils/userUtils';
import { titleize } from '../../../../utils/stringUtils';

const SoundImage = styled('img')`
  width: 100%;
  margin: 10px 0;
  background-color: hsl(210, 100%, 80%);
  height: 100px;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
`

const StyledAudio = styled('audio')`
  width: 100%;
`

export default function SoundCard(props: { sound: Sound }) {
  const [processingStatus, setProcessingStatus] = React.useState(props.sound.processing);

  useEffect(() => {
    let pollingInterval: NodeJS.Timeout;

    const checkStatus = async () => {
      try {
        axios.defaults.headers.common['X-CSRF-Token'] = getCsrfToken();
        const { data } = await axios.get(`/sounds/${props.sound.id}/status`);
        setProcessingStatus(data.processing);

        if (data.processing === 100) {
          clearInterval(pollingInterval);
        }
      } catch (error) {
        console.error('Error fetching sound status:', error);
      }
    };

    if (processingStatus < 100) {
      pollingInterval = setInterval(checkStatus, 2000); // Poll every 2 seconds
    }

    return () => {
      if (pollingInterval) {
        clearInterval(pollingInterval);
      }
    };
  }, [props.sound.id, processingStatus]);

  const soundCardTitle = titleize(props.sound.prompt_text);

  return (
    <Card sx={{ height: '100%' }}>
      <CardContent>
        <Typography variant="h6">{soundCardTitle}</Typography>
        {processingStatus === 100 ? (
          <SoundImage src={props.sound.waveform_image_url} />
        ) : (
          <CircularProgress variant="determinate" value={processingStatus} />
        )}
        <StyledAudio controls src={props.sound.mp3_url} />
      </CardContent>
    </Card>
  );
}
