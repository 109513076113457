import _ from "lodash"

function titleize(str: string) {
  return _.startCase(_.camelCase(str))
}

function truncateWords(str: string, number_of_words: number) {
  return str.split(" ").splice(0, number_of_words).join(" ")
}

function pluralize(count: number, noun: string, suffix = "s") {
  return `${noun}${count !== 1 ? suffix : ""}`
}

function formatTimestamp(timestamp: number) {
  let date = new Date(timestamp)
  return date.toLocaleString()
}

function timeAgoInWords(timestamp: number) {
  let pastDate = new Date(timestamp)

  if (isNaN(pastDate.getTime())) {
    return "Invalid date"
  }

  const now = new Date()
  const seconds = Math.floor((now.getTime() - pastDate.getTime()) / 1000)
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)
  const months = Math.floor(days / 30.436875) // Average days per month
  const years = Math.floor(days / 365.25) // Average days per year, accounting for leap years

  if (seconds < 60) {
    return `${seconds} seconds ago`
  } else if (minutes < 60) {
    return `${minutes} minutes ago`
  } else if (hours < 24) {
    return `${hours} hours ago`
  } else if (months < 1) {
    return `${days} days ago`
  } else if (months < 12) {
    return `${months} months ago`
  } else {
    return `${years} years ago`
  }
}

export { titleize, truncateWords, pluralize, formatTimestamp, timeAgoInWords }
