import * as React from 'react';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Copyright from '../internals/components/Copyright';
import SoundCard from './SoundCard';
import PromptForm from '../../PromptForm';
import { Sound } from '../../../../types/Sound';

export default function MainGrid(props: { sounds: Sound[] } = { sounds: [] }) {
  return (
    <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
      <Grid
        container
        spacing={2}
        columns={12}
        sx={{ mb: (theme) => theme.spacing(2) }}
      >
        <Grid size={{ xs: 12, sm: 12, lg: 12 }}>
          <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
            Generate any audio sample
          </Typography>
          <PromptForm />
        </Grid>
        {props.sounds?.map((sound) => (
          <Grid size={{ xs: 12, sm: 6, lg: 3 }} key={sound.id}>
            <SoundCard sound={sound} />
          </Grid>
        ))}
      </Grid>
      <Copyright sx={{ my: 4 }} />
    </Box>
  );
}
