function getCsrfToken(): string | null {
  if (typeof(document) !== "undefined") {
    const element = document.querySelector('[name="csrf-token"]') as HTMLMetaElement | null
    if (!element) return null
    return element.content
  }
  return null
}

export { getCsrfToken }
