import * as React from 'react';
import { Button, Card, CardContent, CircularProgress, FormControl, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { getCsrfToken } from '../../utils/userUtils';

import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import InsightsRoundedIcon from '@mui/icons-material/InsightsRounded';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export default function PromptCard() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [prompt, setPrompt] = React.useState('');
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [isProcessed, setIsProcessed] = React.useState(false);
  const [promptId, setPromptId] = React.useState<number | null>(null);

  const checkStatus = async (id: number) => {
    try {
      axios.defaults.headers.common['X-CSRF-Token'] = getCsrfToken();
      const response = await axios.get(`/prompts/${id}/status`);
      const progresses = response.data.progresses;
      console.log('progresses', progresses);

      // Check if all progress values are 100%
      const isComplete = progresses.every((progress: number) => progress === 100);

      if (isComplete) {
        setIsProcessing(false);
        setIsProcessed(true);
        setTimeout(() => window.location.reload(), 2000);
      } else {
        // Continue polling if not complete
        setTimeout(() => checkStatus(id), 2000); // Poll every 2 seconds
      }
    } catch (error) {
      console.error('Error checking prompt status:', error);
      setIsProcessing(false);
    }
  };

  const handleSubmit = async () => {
    try {
      setIsProcessing(true);
      setIsProcessed(false);

      axios.defaults.headers.common['X-CSRF-Token'] = getCsrfToken();
      const response = await axios.post('/prompts', {
        prompt_text: prompt
      });

      const newPromptId = response.data.prompt.id;
      setPromptId(newPromptId);

      // Start polling
      checkStatus(newPromptId);
    } catch (error) {
      console.error('Error submitting prompt:', error);
      setIsProcessing(false);
    }
  };

  return (
    <Card sx={{ height: '100%' }}>
      <CardContent>
        <InsightsRoundedIcon />
        <Typography
          component="h2"
          variant="subtitle2"
          gutterBottom
          sx={{ fontWeight: '600' }}
        >
          Prompt
        </Typography>
        <FormControl sx={{ width: { xs: '100%', md: '100%' }, margin: "16px 0" }} variant="outlined">
          <TextField
              label="Prompt"
              variant="outlined"
              fullWidth
              multiline
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              disabled={isProcessing}
          />
        </FormControl>
        <Button
          variant="contained"
          size="small"
          color="primary"
          fullWidth={isSmallScreen}
          onClick={handleSubmit}
          disabled={isProcessing}
          endIcon={isProcessing ? <CircularProgress size={20} color="inherit" /> : <ChevronRightRoundedIcon />}
          sx={{
            '&.Mui-disabled': {
              color: theme.palette.primary.contrastText, // or theme.palette.primary.contrastText
            }
          }}
        >
          {isProcessing ? 'Processing...' : 'Generate'}
        </Button>
      </CardContent>
    </Card>
  );
}
